import React from "react";

const baseHeight = 50;

export const StaticAdSpace = () => {

  return (
    <div style={{ display: 'flex', marginTop: 20, marginBottom: 20, flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-evenly', width: '100%' }}>
      <div onClick={() => window.open("https://www.wasa.com/nb-no/", "_blank")} style={{ cursor: 'pointer', flex: 1, padding: 10, minWidth: 200, maxHeight: 30, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
        <img src="/assets/sponsors/wasa.png" style={{ alignSelf: 'flex-end', width: '100%', maxHeight: 30, objectFit: 'contain' }} />
      </div>
      <div onClick={() => window.open("https://www.skoda-auto.no/", "_blank")} style={{ cursor: 'pointer', flex: 1, padding: 10, minWidth: 200, maxHeight: 30, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
        <img src="/assets/sponsors/skoda-2.png" style={{ alignSelf: 'flex-end', width: '100%', maxHeight: 30, objectFit: 'contain' }} />
      </div>
      <div onClick={() => window.open("http://www.xxl.no/?utm_source=stolpejakten&utm_medium=sponsorat&utm_campaign=stolpejakten2025", "_blank")} style={{ cursor: 'pointer', flex: 1, padding: 10, minWidth: 200, maxHeight: 30, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
        <img src="/assets/sponsors/xxl1.png" style={{ alignSelf: 'flex-end', width: '100%', maxHeight: 35, objectFit: 'contain' }} />
      </div>
      <div onClick={() => window.open("http://www.imsdal.no", "_blank")} style={{ cursor: 'pointer', flex: 1, padding: 10, minWidth: 200, maxHeight: 30, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
        <img src="/assets/sponsors/imsdal.png" style={{ alignSelf: 'flex-end', width: '100%', maxHeight: 35, objectFit: 'contain' }} />
      </div>
    </div>
  )
}